<template>
<v-row>
  <v-col cols="12" md="6">
      <search-box
          :hidden-clear="true"
          label="Cliente"
          popup-label="Selecione um Cliente"
          :id.sync="value.idCliente"
          :nome.sync="value.nomeCliente"
          :value="cliente"
          :loader-fn="loaderClientes"
          item-key="id"
          item-text="nome"
          :rules="[
            this.$validators.notNullOrUndefined
        ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Cliente encontrado
          </p>
        </template>
      </search-box>
    </v-col>
  <v-col cols="12" sm="6">
    <search-box
        :hidden-clear="true"
        :emit-all-properties="true"
        label="* Competição"
        popup-label="Selecione uma Competição Ativa"
        :id.sync="value.idCompCompeticao"
        :descricao.sync="value.descInscricaoCompeticao"
        :idCompEvento.sync="value.idCompEvento"
        :flgTipoComp.sync="value.flgTipoComp"
        :flgValorUnico.sync="value.flgValorUnico"
        :valorInscricao.sync="value.valorInscricao"
        :unicas.sync="value.unicas"
        :value="competicao"
        :loader-fn="loaderCompeticoesAtivas"
        item-key="id"
        item-text="descricao"
        :rules="[
              this.$validators.notNullOrUndefined
          ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhuma Competição Ativa encontrada
        </p>
      </template>
    </search-box>
  </v-col>
    <v-col cols="12" sm="12">
    <v-row v-if="value.flgTipoComp === 'UN'">
      <v-col cols="12" sm="12">
        <strong>Opções disponíveis</strong> <v-icon @click="limpaProva()" >mdi-reload</v-icon>
        <v-radio-group v-model="opcaoInscricao" :dense="true" column @change="selecionaOpcao">
          <v-radio
            v-for="option in this.value.unicas"
            :key="option.id"
            color="red"
            :label="option.descricao"
            :value="option.id"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="12" sm="12">
    <v-text-field dense
        label="Valor da Inscrição"
        prefix="R$"
        outlined
        disabled
        v-model="value.valorInscricao" 
        type="number"/>
  </v-col>
</v-row>
</template>

<script>
import SearchBox from "../../../components/shared/common/SearchBox";
import {findAllCompeticoesAtivas} from "../../../api/competicoes";
import {findAllClienteLogadoMaisSeusDependentes as findAllClientes} from '../../../api/clientes';
import LookupDominio from "../../../components/shared/common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import moment from 'moment';

export default {
  name: "MinhasInscricaoForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO
      },
      opcaoInscricao: null
    }
  },
  computed: {
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nomeCliente
      }
    },
    statusAtivo() {
      if (this.value.status == null) {
        this.value.status = "A"
      };
    },
    competicao() {
      if(this.value.idCompCompeticao == null) {
        this.value.descInscricaoCompeticao = null;
        this.value.idCompEvento = null;
        this.value.flgTipoComp = null;
        this.value.flgValorUnico = null;
        this.value.valorInscricao = null;
        this.value.unicas = {};
        this.value.unicasId = null;
        return null
      };
      return {
        id: this.value.idCompCompeticao,
        descricao: this.value.descInscricaoCompeticao,
        idCompEvento: this.value.idCompEvento,
        flgTipoComp: this.value.flgTipoComp,
        flgValorUnico: this.value.flgValorUnico,
        valorInscricao: this.value.valorInscricao,
        unicas: this.value.unicas,
        unicasId: this.value.unicasId
      }
    },
  },
  watch: {
  },
  methods: {
    loaderClientes(page, search) {
      return findAllClientes(page, {
        nome: search
      })
    },
    loaderCompeticoesAtivas(page, search) {
      return findAllCompeticoesAtivas(page, {
        descricao: search
      })
    },
    selecionaOpcao(opcaoId) {
      const selected = this.value.unicas.find(opcoes => opcoes.id === opcaoId);
      if (selected) {
        this.value.unicasId = selected.id;
        this.value.valorInscricao = selected.valor;
      }
    },  
    limpaProva() {
      this.opcaoInscricao = null;
      this.value.valorInscricao = 0;
      this.value.unicasId = null;
    },
    setOpcaoInscricao() {
      this.opcaoInscricao = (this.value.provas.length > 0 ? this.value.provas[0].idOpcao : null);
    }
  },
}
</script>

<style scoped>
.v-textarea textarea[readonly="readonly"] {
    background-color: yellowgreen
}
</style>