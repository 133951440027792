<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <template v-slot:actions:extras>
      <v-btn color="primary"
             @click="criar"
             :block="$vuetify.breakpoint.mobile" :outlined="$vuetify.breakpoint.mobile" :width="'250px'"
             :text="!$vuetify.breakpoint.mobile"> Realizar nova Inscrição
      </v-btn>
    </template>
    <create-minhas-inscricao-dialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>
    <div>
      <minhas-inscricoes-data-table ref="dataTable"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import MinhasInscricoesDataTable from "../../components/shared/datatables/MinhasInscricoesDataTable";
import CreateMinhasInscricaoDialog from "./components/CreateMinhasInscricaoDialog";
import BaseView from "../BaseView";
import {findAll as findAllEvento} from '../../api/eventos';
import {findAll as findAllEtapa} from '../../api/etapas';
import {findAllClienteLogadoMaisSeusDependentes as findAllCliente} from '../../api/clientes';
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateMinhasInscricaoDialog, BaseView, MinhasInscricoesDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          name: 'Ano Competição',
          column: 'anoCompetencia',
          type: 'number'
        },
        {
          name: 'Evento',
          column: 'idCompEvento',
          type: 'autocomplete',
          optionsFactory: (search) => findAllEvento(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },
        {
          name: 'Etapa',
          column: 'idCompEtapa',
          type: 'autocomplete',
          optionsFactory: (search) => findAllEtapa(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        }
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>