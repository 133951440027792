var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
                  disableItemsPerPage: true,
                  itemsPerPageOptions: [_vm.itemsPerPage],
                },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.nomeCliente",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.retornaNomeCliente(item)))])]}},{key:"item.provasFormatada",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.provasFormatada))])]}},{key:"item.dtInscricao",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.dtInscricao,'DD/MM/YYYY')))])]}},{key:"item.tipoAluno",fn:function(ref){
                var item = ref.item;
return [_c('span',{class:_vm.corTipoAluno(item.tipoAluno)},[_vm._v(_vm._s(item.tipoAluno))])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status == "A" ? "Ativo" : "Inativo"))])]}},{key:"item.valorInscricao",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.retornaValorInscricao(item.valorInscricao)))])]}},{key:"item.fatura",fn:function(ref){
                var item = ref.item;
return [_c('span',{class:_vm.corFatura(item.fatura)},[_vm._v(_vm._s(item.fatura))])]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
                var items = ref.items;
                var isSelected = ref.isSelected;
                var select = ref.select;
return [(items.length === 0)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v("Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.")])])],1):_vm._e(),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item"},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(item.id)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.retornaNomeCliente(item))+" "),_c('span',{class:_vm.corTipoAluno(item.tipoAluno)},[_vm._v("("+_vm._s(item.tipoAluno)+")")])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.descInscricaoCompeticao)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.provasFormatada)+" ")]),_c('v-list-item-subtitle',[_c('span',{class:_vm.corFatura(item.fatura)},[_vm._v(_vm._s(item.fatura))]),_vm._v(" "+_vm._s(_vm.retornaValorInscricao(item.valorInscricao))+" ")])],1)],1)}),1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }